///////////////////////////////////////////////////////////
// Typography
///////////////////////////////////////////////////////////

$base-font-size:16px; // Henceforth known as 1em
$font-stack:	'Raleway', sans-serif;
$font-fancy:	'Balthazar',serif;
$serif:			Georgia,serif;
$sans-serif:	Arial,sans-serif;
$icon-font-path:        "../fonts/"; // Glyphicons font path

///////////////////////////////////////////////////////////
// Grid settings
///////////////////////////////////////////////////////////
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

///////////////////////////////////////////////////////////
// Colors
///////////////////////////////////////////////////////////
$brand-primary:         #e41f27;
$red: 					#e41f27;
$dark-red:	 			#b00608;
$black:					#231f20;
$white:					#ffffff;
$dark-gray:				#414042;
$gray:					#636466;
$light-gray:			#939598;
$lightest-gray:			#f2f3f3;
$blue:					#35e1f4;
$brown:					#352b2f;
$light-brown: 			#998e80;

///////////////////////////////////////////////////////////
// Media queries breakpoints
///////////////////////////////////////////////////////////
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs:              480px !default;
// $screen-xs:              30em !default;
$screen-xs-min:          $screen-xs !default;

// Small screen / tablet
$screen-sm:              768px !default;
// $screen-sm:              48em !default;
$screen-sm-min:          $screen-sm !default;

// Medium screen / desktop
$screen-md:              992px !default;
// $screen-md:              62em !default;
$screen-md-min:          $screen-md !default;

// Large screen / wide desktop
$screen-lg:              1200px !default;
// $screen-lg:              75em !default;
$screen-lg-min:          $screen-lg !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:          ($screen-sm-min - 1) !default;
$screen-sm-max:          ($screen-md-min - 1) !default;
$screen-md-max:          ($screen-lg-min - 1) !default;

///////////////////////////////////////////////////////////
// Main Nav
///////////////////////////////////////////////////////////
$navigation-height:115px;
$navigation-height-sm:60px;

///////////////////////////////////////////////////////////
// Navburger
// @link https://github.com/jonsuh/hamburgers
///////////////////////////////////////////////////////////

$hamburger-padding-x                       : 15px !default;
$hamburger-padding-y                       : 15px !default;
$hamburger-layer-width                     : 30px !default;
$hamburger-layer-height                    : 3px !default;
$hamburger-layer-spacing                   : 6px !default;
$hamburger-layer-color                     : #000 !default;
$hamburger-layer-border-radius             : 4px !default;
$hamburger-hover-opacity                   : 0.7 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter    : opacity(50%) !default;

///////////////////////////////////////////////////////////
// webicons
///////////////////////////////////////////////////////////
$webicon-close: "\e5cd";
$webicon-insert_invitation: "\e24f";
$webicon-keyboard_arrow_down: "\e313";
$webicon-keyboard_arrow_left: "\e314";
$webicon-keyboard_arrow_right: "\e315";
$webicon-keyboard_arrow_up: "\e316";
$webicon-mail_outline: "\e0e1";
$webicon-pdf: "\e902";
$webicon-talk: "\e903";
$webicon-google-plus: "\e906";
$webicon-facebook: "\e907";
$webicon-instagram: "\e908";
$webicon-twitter: "\e909";
$webicon-vine: "\e90a";
$webicon-flickr: "\e90b";
$webicon-tumblr: "\e90c";
$webicon-linkedin: "\e90d";
$webicon-lastfm: "\e90e";
$webicon-pinterest: "\e90f";
$webicon-youtube: "\e910";
$webicon-vimeo: "\e911";
$webicon-github: "\e912";
$webicon-wordpress: "\e913";
$webicon-yelp: "\e914";
$webicon-houzz: "\e915";
$webicon-rss: "\e916";
$webicon-basecamp: "\eab2";
$webicon-tux: "\eabd";
$webicon-appleinc: "\eabe";
$webicon-android: "\eac0";
$webicon-windows8: "\eac2";
$webicon-soundcloud: "\eac3";
$webicon-paypal: "\ead8";
$webicon-up: "\f0d8";
$webicon-info2: "\f129";
$webicon-peace: "\f25b";
$webicon-snapchat-ghost: "\f2ac";
