.btn {
	display:inline-block;
	width:auto;
	position:relative;
	padding:12px 20px;
	text-align:center;
	font-family:$font-stack;
	font-size:em(14px);
	line-height:1;
	font-weight:400;
	color:#fff;
	text-decoration:none !important;
	// border:0px solid #ccc;
	border-radius:0 !important;
	text-transform:uppercase;
	white-space:normal;
}

.btn:hover, .btn:active, .btn:visited {
	color:#fff;
	text-decoration:none !important;
}

.btn-sm {
	font-size:em(12px);
}

.btn-lg {
	font-size:em(24px);
}

.big-btn {
	// display:block;
	padding:20px 45px;
	font-weight:300;
	line-break:normal;
	
	.btn-header {
		font-size:em(24px);
	}
}

.btn-fancy {
	padding:20px 30px;
	font-weight:700;
	border-radius:5px !important;
	background-color:$red;
	box-shadow:inset 0 -27px 10px rgba($black,0.2);
	min-height:34px;
	@include transition(all .15s ease-in-out);
	
	&:hover {
		background-color:$dark-gray;
		box-shadow:inset 0 -27px 10px rgba($black,0.85);
	}	
}



.btn-default, .btn-default:focus { border-color:$brown; background-color:$brown; }
.btn-red, .btn-red:focus { background-color:$red !important; border-color:$red !important; color:$white !important; }
.btn-blu, .btn-blu:focus { background-color:$blue !important; border-color:$blue !important; color:$black !important; }
.btn-dark, .btn-dark:focus { background-color:$dark-gray !important; border-color:$dark-gray !important; color:$white !important; }
.btn-hlw, .btn-hlw:focus { background-color:transparent !important; color:$light-brown; border-color:$light-brown !important; color:$light-brown !important; }

.btn-primary:hover, .btn-primary:active, .btn-primary.active, 
.btn-default:hover, .btn-default:active, .btn-default.active, 
.btn-red:hover, .btn-red:active, .btn-red.active, 
.btn-blu:hover, .btn-blu:active, .btn-blu.active, 
.btn-dark:hover, .btn-dark:active, .btn-dark.active, 
.btn-hlw:hover, .btn-hlw:active, .btn-hlw.active, 
.comment-form input[type=submit]:hover  
{
	background-color:$light-brown !important; border-color:$light-brown !important; color:$brown !important; outline:0 !important; cursor:pointer;
}

.btn-primary, .comment-form input[type=submit] {
    color:$white;
    background-color:$brown;
    border-color:$brown;
}

.form-control {
	border-radius:0;
}

.button-group {

	button.btn {
		display:block;
		margin-bottom:5px;
		width:100%;
		
		@media (min-width:$screen-xs-min){
			display:inline-block;
			width:auto;
		}
	}
}

.input-group-btn {
	.btn {
		padding-top:0;
		padding-bottom:0;
		line-height:34px;
		border:0 !important;
		border-radius:0 5px 5px 0 !important;
		
		
		&:hover {
			background-color:$black !important; border-color:$black !important; color:$white !important; 
		}
	}
}









































