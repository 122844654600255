//
footer.content-info {
	background-color:$dark-gray;
	
	img {
		max-width:100%;
		height:auto;
	}
}

.footer-container {
	color:$white;
	background-color:$dark-gray;
	padding-bottom:25px;
	
	.footer-line {
		padding-top:15px;
		padding-bottom:15px;
		margin-top:15px;
		margin-bottom:15px;
		border-bottom:1px solid $black;
		@extend .clearfix;
		
		&:last-of-type {
			border-bottom:0;
			padding-bottom:0;
			margin-bottom:0;
		}
	}
	
	.footer-social {
		// float:left;
		margin-top:25px;
		@include clearfix();
	
		ul {
			list-style:none;
			margin:0;
			padding:0;
			text-align:left;
			
			li {
				display:inline-block;
				padding:10px;
				
				a {
					color:$light-brown;
					font-size:em(24px);
					text-decoration:none;
					@include transition(color .15s ease-in-out, background-color .15s ease-in-out, opacity .15s ease-in-out, display .15s ease-in-out);
				
					&:hover,
					&:active {
						color:$white;
					}
				}
			}
		}
	}
	
	.footer-search {
		
		input {
			border-radius:0;
		}
		
		.search-form .search-submit {
			color:$white;
			background-color:$black;
			border-color:$black;
		}
	}
	
	.footer-content {
		margin-top:25px;
		font-size:em(14px);
		line-height:1.4;
		@include clearfix();
		
		.footer-tagline {
			font-size:em(16px);
			font-weight:700;
		}
	}
	
	.footer-contact {
		font-size:em(14px);
		
		@media (min-width:$screen-md-min){
			font-size:100%;
		}
	}
	
	.legal-text {
		margin-top:25px;
		font-size:em(14px);
		color:$light-brown;
	}
	
	.footer-nav {
	
		.nav>li>a {
			display:inline-block;
			padding:0;
			text-transform:uppercase;
		}
	
		ul {
			list-style:none;
			margin:0;
			padding:0;
			
			li {
				display:block;
				margin:0 0 10px;
				padding:0 0 10px;
				
				&:last-of-type {
					margin:0;
					padding:0;
				}
				
				a {
					font-size:em(14px);
					font-weight:700;
					color:$white;
					text-decoration:none;
					
					&:hover {
						color:$red;;
					}
				}
				
				ul {
					display:inline-block;
					margin-left:8px;
					padding-left:8px;
					border-left:1px solid $black;
				}
			}
			
			li li {
				display:inline-block;
				margin:0;
				padding:0;
				
				a {
					display:block;
					margin:0 8px 0 0;
					padding:0 8px 0 0;
					font-weight:400;
					border-right:1px solid $black;
				}
				
				&:last-of-type a {
					margin:0;
					padding:0;
					border:0;
				}
			}
		}
	}
	
	.request-button {
		margin-top:25px;
		display:block;
	}
}