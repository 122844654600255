//
// Mixins
//

.clearfix {
	&:after {
		content:'';
		display:table;
		clear:both; 
	}
	*zoom:1;
}

@mixin clearfix() {
	&:after {
		content:'';
		display:table;
		clear:both; 
	}
	*zoom:1;
}

@mixin transition($prop_time_easing...){ // for multiple arguments as PROP1 TIME1 EASING1, PROP2 TIME2 EASING2, etc.
	-webkit-transition:$prop_time_easing;
	   -moz-transition:$prop_time_easing;
	    -ms-transition:$prop_time_easing;
	     -o-transition:$prop_time_easing;
	        transition:$prop_time_easing;
}

@mixin rounded($value){
	-webkit-border-radius:$value;
	-moz-border-radius:$value;
	border-radius:$value;
}

/*============================================================================
  Prefixer mixin for generating vendor prefixes:
    - Based on https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/addons/_prefixer.scss
    - Usage:

      // Input:
      .element {
        @include prefixer(transform, scale(1), ms webkit spec);
      }

      // Output:
      .element {
        -ms-transform:scale(1);
        -webkit-transform:scale(1);
        transform:scale(1);
      }
==============================================================================*/

@mixin prefixer($property, $value, $prefixes) {
  @each $prefix in $prefixes {
    @if $prefix == webkit {
      -webkit-#{$property}:$value;
    } @else if $prefix == moz {
      -moz-#{$property}:$value;
    } @else if $prefix == ms {
      -ms-#{$property}:$value;
    } @else if $prefix == o {
      -o-#{$property}:$value;
    } @else if $prefix == spec {
      #{$property}:$value;
    } @else  {
      @warn "Unrecognized prefix:#{$prefix}";
    }
  }
}

@mixin transform($transform) {
  @include prefixer(transform, $transform, ms webkit spec);
}

@mixin user-select($value:none) {
  @include prefixer(user-select, $value, webkit moz ms spec);
}

@mixin backface($visibility:hidden) {
  @include prefixer(backface-visibility, $visibility, webkit spec);
}

@function em($target, $context:$base-font-size) {
  @if $target == 0 {
    @return 0;
  }
  @return $target / $context + 0rem;
}

@function color-control($color) {
  @if (lightness( $color ) > 40) {
    @return #000;
  }
  @else {
    @return #fff;
  }
}

// HEX to RGBA backgorund
@mixin rgba-background($hexcolor, $opacity) {
	background-color:$hexcolor; // fallback, do we still do that?
	background-color:rgba($hexcolor, $opacity); 
}


// ==========================================================================
// Helper classes
// ==========================================================================

.ir {
    background-color:transparent;
    border:0;
    overflow:hidden;
    *text-indent:-9999px;
}

.ir:before {
    content:"";
    display:block;
    width:0;
    height:150%;
}

.hidden {
    display:none !important;
    visibility:hidden;
}

.visuallyhidden {
    border:0;
    clip:rect(0 0 0 0);
    height:1px;
    margin:-1px;
    overflow:hidden;
    padding:0;
    position:absolute;
    width:1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip:auto;
    height:auto;
    margin:0;
    overflow:visible;
    position:static;
    width:auto;
}

.invisible {
    visibility:hidden;
}

@media (max-width:$screen-md-min){
	.mobile-hide {
		display:none;
	}
}

@media (min-width:$screen-md-min){
	.desktop-hide {
		display:none;
	}
}

// layouts
.top-center {
	position:absolute;
	top:0;
	width:100%;
	text-align:center;
}

.bottom-center {
	position:absolute;
	bottom:0;
	width:100%;
	text-align:center;
}

.middle-center {
	position: absolute;
    top: 50%;
    left: 50%;
	width:100%;
	height:auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

// ==========================================================================
// Website Icons
// ==========================================================================
// usage:<span class="webicon webicon-facebook"></span>

@font-face {
  font-family: 'tes';
  src:  url('#{$icon-font-path}/tes.eot?qffffb');
  src:  url('#{$icon-font-path}/tes.eot?qffffb#iefix') format('embedded-opentype'),
    url('#{$icon-font-path}/tes.ttf?qffffb') format('truetype'),
    url('#{$icon-font-path}/tes.woff?qffffb') format('woff'),
    url('#{$icon-font-path}/tes.svg?qffffb#tes') format('svg');
  font-weight: normal;
  font-style: normal;
}

.webicon, [class^="webicon-"], [class*=" webicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tes' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.webicon-close {
  &:before {
    content: $webicon-close;
  }
}
.webicon-insert_invitation {
  &:before {
    content: $webicon-insert_invitation;
  }
}
.webicon-keyboard_arrow_down {
  &:after {
    content: $webicon-keyboard_arrow_down;
  }
}
.webicon-keyboard_arrow_left {
  &:before {
    content: $webicon-keyboard_arrow_left;
  }
}
.webicon-keyboard_arrow_right {
  &:before {
    content: $webicon-keyboard_arrow_right;
  }
}
.webicon-keyboard_arrow_up {
  &:before {
    content: $webicon-keyboard_arrow_up;
  }
}
.webicon-mail_outline {
  &:before {
    content: $webicon-mail_outline;
  }
}
.webicon-pdf {
  &:before {
    content: $webicon-pdf;
  }
}
.webicon-talk {
  &:before {
    content: $webicon-talk;
  }
}
.webicon-google-plus {
  &:before {
    content: $webicon-google-plus;
  }
}
.webicon-facebook {
  &:before {
    content: $webicon-facebook;
  }
}
.webicon-instagram {
  &:before {
    content: $webicon-instagram;
  }
}
.webicon-twitter {
  &:before {
    content: $webicon-twitter;
  }
}
.webicon-vine {
  &:before {
    content: $webicon-vine;
  }
}
.webicon-flickr {
  &:before {
    content: $webicon-flickr;
  }
}
.webicon-tumblr {
  &:before {
    content: $webicon-tumblr;
  }
}
.webicon-linkedin {
  &:before {
    content: $webicon-linkedin;
  }
}
.webicon-lastfm {
  &:before {
    content: $webicon-lastfm;
  }
}
.webicon-pinterest {
  &:before {
    content: $webicon-pinterest;
  }
}
.webicon-youtube {
  &:before {
    content: $webicon-youtube;
  }
}
.webicon-vimeo {
  &:before {
    content: $webicon-vimeo;
  }
}
.webicon-github {
  &:before {
    content: $webicon-github;
  }
}
.webicon-wordpress {
  &:before {
    content: $webicon-wordpress;
  }
}
.webicon-yelp {
  &:before {
    content: $webicon-yelp;
  }
}
.webicon-houzz {
  &:before {
    content: $webicon-houzz;
  }
}
.webicon-rss {
  &:before {
    content: $webicon-rss;
  }
}
.webicon-basecamp {
  &:before {
    content: $webicon-basecamp;
  }
}
.webicon-tux {
  &:before {
    content: $webicon-tux;
  }
}
.webicon-appleinc {
  &:before {
    content: $webicon-appleinc;
  }
}
.webicon-android {
  &:before {
    content: $webicon-android;
  }
}
.webicon-windows8 {
  &:before {
    content: $webicon-windows8;
  }
}
.webicon-soundcloud {
  &:before {
    content: $webicon-soundcloud;
  }
}
.webicon-paypal {
  &:before {
    content: $webicon-paypal;
  }
}
.webicon-up {
  &:before {
    content: $webicon-up;
  }
}
.webicon-info2 {
  &:before {
    content: $webicon-info2;
  }
}
.webicon-peace {
  &:before {
    content: $webicon-peace;
  }
}
.webicon-snapchat-ghost {
  &:before {
    content: $webicon-snapchat-ghost;
  }
}

// ==========================================================================
//   Base
// ==========================================================================
html {
	font-size:$base-font-size; // base font size = 16px
	position:relative;
	min-height:100%;
	-webkit-text-size-adjust:100%; 
	-ms-text-size-adjust:100%;
	-ms-overflow-style:scrollbar; // IE scrollbar hack
	
	@media (min-width:1921px ){
	font-size:$base-font-size; // base font size = 16px
	}
}

body {
	font-family:$font-stack;
	font-size:100%; // base font size = 16px
	margin:0; 
	padding:0; 
	background-color:#f2f3f3;
	
	@media (min-width:1921px ){
		font-size:150%; 
	}
}

#tinymce {
	background-color:#fff;
	background-image:none;
}

body, button, input, select, textarea {
	line-height:1.618; 
}

.wrap, .container {
	position:relative;
}

.main {
	padding-left:0;
	padding-right:0;
}

/* ==================================================
   Code and Pre
   ================================================== */
code, pre {
	padding:0 3px 2px;
	color:#232527;
	font-size:em(12px);
	font-family:Monaco, Menlo, Consolas, "Courier New", monospace;
	-webkit-border-radius:3px;
       -moz-border-radius:3px; 
	        border-radius:3px;
}

code {
	padding:2px 4px; 
	border:1px solid #dde4e9; 
	background-color:#f6f6f6; 
	color:#232527;
	white-space:nowrap;
}

pre {
  	display:block;
  	margin:30px;
  	padding:15px;
  	border:1px solid #dde4e9;
  	-webkit-border-radius:4px;
       -moz-border-radius:4px;
            border-radius:4px;
  	background-color:#fcfcfc;
  	white-space:pre;
  	white-space:pre-wrap;
  	word-wrap:break-word;
  	word-break:break-all;
  	font-size:12px;
  	line-height:20px;
}

pre code {
  	padding:0;
  	border:0;
  	background-color:transparent;
  	color:inherit;
  	white-space:pre;
  	white-space:pre-wrap;
}

.pre-scrollable {
  	overflow-y:scroll;
  	max-height:340px;
}

.badge, .dropdown-header, .dropdown-menu>li>a, .input-group-addon, .input-group-btn, .label, .text-nowrap, code {
    white-space:normal;
}

object, iframe {
	max-width:100%;
	// height:auto;
}


/* ==================================================
   Typography
   ================================================== */
h1, h2, h3, h4, h5, h6 {
	margin:0 0 10px 0; 
	font-weight:700; 
	font-family:$font-fancy;
	line-height:1.1;
	color:$black;
	-moz-font-feature-settings:"lnum" 1; 
	-ms-font-feature-settings:"lnum" 1; 
	-o-font-feature-settings:"lnum" 1; 
	-webkit-font-feature-settings:"lnum" 1; 
	font-feature-settings:"lnum" 1;
	font-variant-numeric: lining-nums;
	
	a, a:hover {
		text-decoration:none;
	}
}

h1 {
	font-size:em(32px);
}

h1.entry-title {
	margin-top:45px;
}

h1.page-title {
	font-size:em(22px);
	line-height:1.05;

	@media (min-width:$screen-xs-min){
		font-size:calc(3.51vw + 5.125px); // y = mx + b; m = (y2 - y1)/(x2 - x1); b = y1 - m * x1
	}
	
	@media (min-width:$screen-md-min){
		font-size:em(40px);
	}
	
	small {
		font-size:em(14px);
		font-weight:700;
		color:$black;
		
		@media (min-width:$screen-md-min){
			font-size:em(20px);
		}
	}
}

.gallery .page-header h1,
.search .page-header h1 {
	text-align:center;
}

h2 {
	font-size:em(22px);
	color:$black;
	
	a {
		color:$black;
	}
	
}

h2.entry-title {
	margin-bottom:0;
	padding:0;
	font-size:em(22px);
	background-color:transparent;
}

h1.entry-title,
h2.entry-title {
	line-height:1;
}

h3 {
	font-size:em(24px);
	font-weight:400;
	text-transform:uppercase;
}

h4 {
	font-size:em(20px);
}

h5 {
	font-size:em(18px);
}

h6 {
	font-size:em(15px); 
	font-weight:700;
	text-transform:uppercase;
}

.pinkheadline {
	display:block;
	margin:0 auto;
	padding:8px 0;
	font-size:em(16px);
	line-height:1.3;
	font-weight:700;
	text-align:center;
	color:$red;
	background-color:$dark-gray;
	
	@media (min-width:$screen-xs-min){
		font-size:calc(0.39vw + 14.128px); // bump
	}
	
	@media (min-width:$screen-md-min){
		font-size:em(18px);
	}
	
	a {
		color:$red;
		text-decoration:none;
		
		&:hover {
			color:$white;
		}
	}
}

.page-title {
	font-size:em(30px);
	font-weight:700;
	text-transform:uppercase;
}

.sidebar-dark,
.sidebar-light {
	font-size:em(22px);
}

.sidebar-dark {
	background-color:$dark-gray;
	
	&:hover {
		background-color:$red;
		a {	
			color:$dark-gray;
		}
	}
	
	a {
		display:block;
		color:$red;
		
		&:hover {
			color:$dark-gray;
		}
	}
	
}

.sidebar-light {
	background-color:$red;
	
	&:hover {
		background-color:$dark-gray;
	}
	
	a {
		display:block;
		color:$dark-gray;
		
		&:hover {
			color:$red;
		}
	}
	
}

small {
	font-size:em(12px);
	font-family:$sans-serif;
	
	@media (min-width:1921px ){
		font-size:.851rem!important;
	}
}

p {
	margin:0 0 25px;
	
	&:empty {
		display:none;
	}
}

p,li,ol {
	// font-family:$font-stack;
	line-height:1.4;
	font-size:em(18px);
	
	@media (min-width:1921px ){
		font-size:1.5rem!important;
	}
}

ul,
ol {
	margin-bottom:25px;
}

ol {
	list-style-position:outside;
	padding-left:20px;
	text-align:left;
	
	& > li {
		margin-bottom:15px;
	}
}

.nolist {
	list-style:none;
	margin:0;
	padding:0;
	
	li {
		margin:0;
		padding:0;
	}
}

hr {
	clear:both;
    border-top:solid #e5e5e5;
    border-width:1px 0 0;
    margin:25px 0;
    height:0;
}

hr.hr-small {
	margin:15px 0;
}

hr.hr-clear {
	border-top-color:transparent;
}

.rwd-break { 
	display:none;
	
	@media (min-width:$screen-sm-min) {
		display:block;
	}	
}

.red-text 	{ color:$red; }
.blue-text 	{ color:$blue; }
.black-text { color:$black; }
.white-text { color:$white; }

blockquote {
    padding: 0 25px;
    margin: 0 0 20px 45px;
	font-family:$font-fancy;
    font-size:em(18px);
	font-weight:700;
	color:$light-brown;
	border-left: 5px solid $light-brown;
	
	@media (min-width:$screen-sm-min) {
		font-size:em(24px);
	}
	
	p:last-of-type {
		margin-bottom:0;
	}
}

/* ==================================================
   Images
   ================================================== */
img {
	border:0;
	outline:none;
}

.wrap figure,
.wrap img {
	max-width:100% !important;
	height:auto;
}

.full-width-img {
	width:100%;
	height:auto;
}

.img-overlay {
	background-color:$dark-gray;
	
	img {
		opacity:0.3;
	}
}

/* // Mixin and adjust the regular image class
.thumbnail {
  display: block;
  padding: 0;
  margin-bottom: $line-height-computed;
  line-height: $line-height-base;
  background-color: transparent;
  border: 0 solid $thumbnail-border;
  border-radius: 0;
  // @include transition(border .2s ease-in-out);

  // > img,
  // a > img {
    // @include img-responsive;
    // margin-left: auto;
    // margin-right: auto;
  // }

  // [converter] extracted a&:hover, a&:focus, a&.active to a.thumbnail:hover, a.thumbnail:focus, a.thumbnail.active

  // Image captions
  .caption {
    padding: $thumbnail-caption-padding;
    color: $thumbnail-caption-color;
  }
} */

// Add a hover state for linked versions only
a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: $link-color;
}

/* ==================================================
   Links
   ================================================== */
a { 
	outline:none; 
	border:0;
	color:$red;
	text-decoration:none;
	@include transition(color .15s ease-in-out, background-color .15s ease-in-out, opacity .15s ease-in-out);

	&:hover,
	&:active,
	&:focus {
		outline:none; 
		color:$black; 
		// text-decoration:none;
	}
}

.back-to-top {

	margin:0 auto;
	padding:5px 0;
	font-size:em(21px);
	font-weight:700;
	text-align:center;
	color:$red;
	background-color:$dark-gray;
	
	a {
		color:$red;
		text-decoration:none;
		display:block;
		text-transform:uppercase;
		
		&:hover {
			color:$white;
		}
	}
	
}
