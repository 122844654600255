// posts page
h1.entry-title {
    margin-top:0;
}

.entry-summary {
	header {
		background-color:$white;
		padding:10px;
	}
}
	
.cat-name {
	font-size:em(14px);
	color:$blue;
	text-transform:uppercase;
}

.post-thumb {

	img {
		display:block;
		margin:-8.75% 0;
		width:100%;
		height:auto;
		
	}

	/*
	
	// YouTube thumbnail css hackery
	
	hqdefault = 480 x 360 = 4:3 = 75% = 0.75
	
	16:9 = 56.25% = 0.5625
	
	0.75 - 0.5625 = 0.1875 / 2 = 0.09375
	
	need to crop 9.375%
	
	my thumbs are 800 x 460 = 42:23 = 0.575
	
	0.75 - 0.575 = 0.175 = 17.5% / 2 = 8.75%
	
	
	*/
	
}

// single post page
.feat-image {
	margin-bottom:25px;
}

.post-filter {
	margin:45px auto 0; 
}

.load-more {
	margin-top:45px;
}

.entry-content {
	@include clearfix();
}

.post-content footer {
    margin-top: 50px;
    margin-bottom: 50px;
    padding-top: 50px;
    border-top: 1px solid $light-gray;
}

// sidebar
aside {
	h5 {
		font-size:em(15px);
		text-transform:uppercase;
	}
	
	.cat-list {
		margin:0;
		padding:0;
		list-style:none;
		
		li {
			margin:0;
			padding:0;
		}
	}
}

.sidebar-item {	
	margin-bottom:25px;
	padding-bottom:25px;
	border-bottom:1px solid $light-gray;
	
	&:last-of-type {
		margin-bottom:0;
		padding-bottom:0;
		border-bottom:0 solid $light-gray;
	}
}

.posts-feat-item {	
	margin-bottom:10px;
	
	h6 {
		margin-top:5px;
		text-transform:none;
		line-height:1.1;
	}
}


// post nav
.posts-nav {
	@include clearfix();
	
	.posts-nav-old {
		float:left;
	}
	
	.posts-nav-new {
		float:right;
	}
}

// comments
.show-comments {
	display:inline-block;
	margin:0 auto;
	padding:5px 25px;
	font-weight:bold;
	color:$red;
	text-align:center;
	text-transform:uppercase;
	border-top:1px solid $red;
	border-bottom:1px solid $red;
	cursor:pointer;
}

#comments {
	display:none;
	margin-top:25px;
}





















































