
/////////////////////////////////////////////////
// top banner
/////////////////////////////////////////////////
.top-banner {
	background-color:$brown;
	box-shadow:inset 0 -20px 10px rgba($black,0.4);
	min-height:34px;
	text-align:center;
	
	// @media (min-width:$screen-md-min){
		// display:flex;
		// justify-content:flex-end;
		// justify-content:space-between;
		// align-items:center;
	
		// & > div {
			// width:33.33333333%;
		// }
	// }
}
	
.header-announcement {
	text-align:center;
	font-size:em(14px);
	font-weight:700;
	line-height:34px;
	color:$black;
	
	a {
		color:$black;
	}
}	
	
.header-social {
	@include clearfix();

	ul {
		
		list-style:none;
		margin:0 auto;
		padding:0;
		text-align:center;
		
		@media (min-width:$screen-md-min){
			float:right;
			margin:0 15px 0  0;
		}
		
		li {
			display:inline-block;
			padding:10px;
			
			a {
				color:$light-brown;
				font-size:em(18px);
				text-decoration:none;
				@include transition(color .15s ease-in-out, background-color .15s ease-in-out, opacity .15s ease-in-out, display .15s ease-in-out);
			
				&:hover,
				&:active {
					color:$white;
				}
			}
		}
	}
}

/////////////////////////////////////////////////
// main nav
/////////////////////////////////////////////////
.banner {
	position:relative;
	box-shadow:0 0 10px rgba($black,0.5);
	
	.nav-main {
		// position:relative;
		background-color:$light-brown;
		border:none;
		border-radius:0;
		z-index:100;
		padding:0;	
		
		@media (min-width:$screen-lg-min){
			padding:0 15px;			
		}
		
		@media (min-width:$screen-lg-min){
			padding-left:calc(8.33333333% + 15px);
			padding-right:calc(8.33333333% + 15px);
		}
		
		.nav-wrap {
			position:relative;
			// min-height:80px;			
		}
	}

	.brand {
		position:absolute;
		top:8px;
		left:15px;
		margin:0;
		width:243px;
		height:43px;
		text-indent:-999em;
		background-image:url(../images/dr-door-tx.png);
		background-repeat:no-repeat;
		background-size:contain;
		display:block;
		z-index:101;
		
		@media (min-width:$screen-md-min){
			top:31px;
			width:300px;
			height:53px;
		}
		// @media (min-width:$screen-lg-min){
			// top:23px;
			// width:300px;
			// height:69px;
		// }
		
		
	}
}

// navigation
.navbar-header {
    float: none;
	min-height:$navigation-height-sm; // 60px

	@media (min-width:$screen-md-min){
		float:right;
		min-height: $navigation-height; // 115px
	}
}	

.navbar-collapse {
	padding:0;
	border-top:0 solid transparent;
	@include clearfix();
}

.navbar-collapse.collapse {
	@media (min-width:$screen-sm-min){
		// display: none!important;
		// height: 0!important;
		padding-bottom: 0;
		overflow: visible!important;
	}

	@media (min-width:$screen-md-min){
		// display: block!important;
		display: flex !important;
		flex-flow:column nowrap;
		// justify-content:space-around;
		justify-content:flex-end;
		align-items:flex-end;
		// align-content:flex-end;
		// height: auto!important;
		height: $navigation-height !important;
		padding-bottom: 0;
		overflow: visible!important;
	}
}

.navbar-collapse.collapsing {
	background-color:$light-brown;
}

.collapse.in {
    display: block!important;
	height:auto!important;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
	border-color:transparent;
	clear:both;
}

ul#menu-main-navigation {
	list-style:none;
	margin:0;
	padding:0;
	display: flex;
	flex-flow:column nowrap;
	// background-color:$white;
	// @include clearfix();
	
	@media (min-width:$screen-md-min){
		flex-flow:row nowrap;
		// float:right;
		// clear:both;
	}

	li {
		display:block;
		margin:0;
		padding:0;
		
		@media (min-width:$screen-md-min){
			float:left;
			position:relative;
		}
		
		@media (min-width:$screen-md-min){
			&.menu-item-has-children > a {
				padding-right:18px;
				
				
				&::after {
					padding-left: 7px;
					display: inline-block;
					vertical-align: top;
					font-family:'tes';
					font-size:20px;
					font-weight:normal;
					line-height:calc( $navigation-height / 2 );
					-webkit-font-smoothing: antialiased;
					content: "\e313";
				}
			}
		}

		a {
			margin:0;
			padding:8px 0 8px 25px;
			font-size:em(18px);
			font-weight:400; 
			line-height:1.618;
			font-family:$font-fancy;
			color:$white;
			text-align:left;
			text-shadow:none;
			text-transform:uppercase;
			border-bottom:1px solid $gray;
			border-radius:0;
			display:block;
			text-decoration:none !important;
			overflow:hidden;
			@include transition(color .25s ease-in-out, background-color .25s ease-in-out, opacity .25s ease-in-out, box-shadow .25s ease-in-out, display .25s ease-in-out);
			
			@media (min-width:$screen-md-min){
				margin:0;
				padding:0 25px 25px;
				font-size:em(20px);
				line-height:calc( $navigation-height / 2 );
				text-align:center;
				border-bottom:0 solid $black;
			}
		}
		
		& > a {
			text-shadow:1px 1px 2px rgba($black,0.4);
			
			&:hover,
			&:focus,
			&:active {
				text-shadow:none;
			}
		}
		
		.sub-menu {
			margin:0;
			padding: 0;
			box-shadow:0 0 5px rgba($black,0.6);
		
			@media (max-width:$screen-sm-min){
				background-color:lighten($dark-gray,5%);
			}
			
			@media (min-width:$screen-md-min){
				display: none;
				position: absolute;
				// left: 0;
				right:0;
				height:0;
				z-index: 999;
				opacity:0; // http://jsfiddle.net/hashem/9dsGP/2/
				width:250px;
				background-color:$dark-gray;
				@include transition(color .25s ease-in-out, background-color .25s ease-in-out, opacity 2.5s ease-in-out, box-shadow .25s ease-in-out, visibility .25s ease-in-out);
			}

			li {
				float:none;
				padding:0;
				margin:0;
				display:block;
				text-align:left;
				
				a {
					font-weight:400;
					text-transform: capitalize;
					color:$white;
					text-shadow:none;
					
					@media (max-width:$screen-sm-min){
						padding-left:45px;
					}
					
					@media (min-width:$screen-md-min){
						padding:10px 25px;
						font-size:em(14px);
						line-height:1.2;
						text-align:right;
						text-transform:uppercase;
						
						&:hover {
							color:$white;
						}
					}
				}
			}
			
			@media (min-width:$screen-md-min){
				li:hover {
					background-color:$black;
				}
			}
		}
		
		@media (min-width:$screen-md-min){
			&:hover .sub-menu {
				display: block;
				opacity:1;
				height:auto;
			}
		}
	}
}


@media (min-width:$screen-md-min){

	ul#menu-main-navigation > li:hover > a, 
	ul#menu-main-navigation > li > a:hover, 
	ul#menu-main-navigation > li.active > a {
		position:relative;
		background-color:transparent;
		color:$black;
		text-shadow:none;
	}

	.nav>li>a:focus, .nav>li>a:hover { // bootstrap override
		background-color:transparent;
	}
}

.navbar-toggle {
	position:relative;
	float:right;
	padding:0;
	margin:20px 15px 0 0;
	
	@media (min-width: $screen-sm-min){
		display: block;
	}
	@media (min-width: $screen-md-min) {
		display: none;
	}

	margin-bottom:0;
	background-color:transparent;
	background-image:none;
	border:0 solid transparent;
	border-radius:0;
	
	&:focus,
	&:hover,
	&:active {
		background-color:transparent;
		outline:0;
	}
}

// navburger
.hamburger {
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: $hamburger-hover-transition-duration;
  transition-timing-function: $hamburger-hover-transition-timing-function;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  // margin: 0;
  overflow: visible;

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    }
    @else {
      opacity: $hamburger-hover-opacity;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
  
  &:focus,
  &:active,
  &:hover {
	outline:0;
  }
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

.hamburger {
  .hamburger-inner {
    &,
    &::before,
    &::after {
      background-color: $black;
    }
  }
}
/*
* Squeeze
*/
.hamburger--squeeze {
	.hamburger-inner {
	  transition-duration: 0.1s;
	  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

	  &::before {
		transition: top 0.1s 0.14s ease,
					opacity 0.1s ease;
	  }

	  &::after {
		transition: bottom 0.1s 0.14s ease,
					transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
	  }
	}

	&.is-active {
	  .hamburger-inner {
		transform: rotate(45deg);
		transition-delay: 0.14s;
		transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

		&::before {
		  top: 0;
		  opacity: 0;
		  transition: top 0.1s ease,
					  opacity 0.1s 0.14s ease;
		}

		&::after {
		  bottom: 0;
		  transform: rotate(-90deg);
		  transition: bottom 0.1s ease,
					  transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
		}
	  }
	}
}